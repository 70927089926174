<template src="./formSchedule.html" />

<script>
import {
  BFormCheckboxGroup,
  BAvatar,
  BBadge,
  BBreadcrumb,
  BButton,
  BCard,
  BCol, BDropdown, BDropdownItem,
  BForm, BFormDatepicker,
  BFormGroup, BFormRow,
  BFormInput, BFormInvalidFeedback, BFormSelect,
  BPagination, BFormCheckbox,
  BRow, ModalPlugin, VBTooltip, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { getUser } from '@/auth/utils'
import CreditClassTeacher from '@/views/credit-class-teacher/CreditClassTeacher.vue'
import CreditClassRoom from '@/views/credit-class-room/CreditClassRoom.vue'

export default {
  name: 'FormSchedule',
  components: {
    CreditClassRoom,
    CreditClassTeacher,
    BButton,
    BCol,
    BRow,
    BForm,
    BBreadcrumb,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormCheckbox,
    BFormRow,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormCheckboxGroup,
    VBTooltip,
    ModalPlugin,
    BFormDatepicker,
    BFormInvalidFeedback,
    vSelect,
    VueGoodTable,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
  },
  props: {
    dataSendForm: {
      type: Object,
      default: undefined,
    },

  },
  data() {
    return {
      user: getUser(),
      selectedStatus: '',
      // optOrg: [],
      optDepartment: [],
      optSubject: [],
      optDays: [],
      optTurns: [],
      listDayCheck: [],
      table: {
        fields: [
          {
            field: 'nameDay', width: '110px', thClass: 'text-center', label: 'Thứ', sortable: false, type: 'string',
          },
          {
            field: 'turns', thClass: 'text-center', label: 'Tiết', sortable: false, type: 'string',
          },
        ],
        items: [],
      },
      selectedCreditClass: '',
    }
  },

  async created() {
    this.dataSendForm.isUpdate = false
    // init data FROM API
    await this.getInit()
    if (this.dataSendForm.creditClassId) {
      this.dataSendForm.isUpdate = true
    }
    const dayCheck = this.dataSendForm.dayTurns ? this.dataSendForm.dayTurns : []
    for (let i = 0; i < this.days.length; i++) {
      const item = this.days[i]
      const temp = {}
      temp.id = item.id
      temp.nameDay = item.name
      const turnCheck = dayCheck.find(element => element.dayId == item.id)
      temp.selected = turnCheck ? turnCheck.turnIds : []
      temp.turns = this.optTurns
      this.table.items.push(temp)
    }
    this.dataSendForm.organizationId = this.dataSendForm.organizationId ? this.dataSendForm.organizationId : this.user.orgId
    if (this.dataSendForm.organizationId && this.dataSendForm.isUpdate) {
      await this.onChangeTrainingSystem(this.dataSendForm.trainingSystemId)
      await this.onChangeDepartments(this.dataSendForm.departmentId)
      await this.onChangeCourse(this.dataSendForm.courseId)
      await this.onChangeCourseSemester(this.dataSendForm.courseSemesterId)
      await this.onChangeCreditClass(this.dataSendForm.creditClassId)
    }
  },

  computed: {
    ...mapGetters({
      subjects: 'dropdown/subjects',
      departments: 'dropdown/departments',
      courses: 'dropdown/courses',
      courseSemesters: 'dropdown/courseSemesters',
      creditClass: 'dropdown/creditClasses',
      creditClassStartDates: 'dropdown/creditClassStartDates',
      trainingSystems: 'dropdown/trainingSystems',
      creditClassRooms: 'dropdown/creditClassRooms',
      creditClassTeachers: 'dropdown/creditClassTeachers',
      days: 'day/days',
      turns: 'turn/turns',
    }),
    // calculate data from init data
  },
  watch: {
    // listening change data init
    'dataSendForm.departmentId': function (newVal) {
      this.onChangeDepartments(newVal)
    },

    'dataSendForm.trainingSystemId': function (newVal) {
      this.onChangeTrainingSystem(newVal)
    },

    'dataSendForm.subjectId': function (newVal) {
      this.onChangeSubject(newVal)
    },

    'dataSendForm.courseId': function (newVal) {
      this.onChangeCourse(newVal)
    },

    'dataSendForm.courseSemesterId': function (newVal) {
      this.onChangeCourseSemester(newVal)
    },

    'dataSendForm.creditClassId': function (newVal) {
      this.onChangeCreditClass(newVal)
    },

  },
  methods: {
    ...mapActions({
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getDepartments: 'dropdown/getDepartments',
      getSubjects: 'dropdown/getSubjects',
      getCourses: 'dropdown/getCourses',
      getCourseSemesters: 'dropdown/getCourseSemesters',
      getCreditClass: 'dropdown/getCreditClasses',
      getCreditClassRoom: 'dropdown/getCreditClassRooms',
      getTeacherByCreditClass: 'dropdown/getTeacherByCreditClass',
      getTurns: 'turn/getTurns',
      getDays: 'day/getDays',
    }),
    // handle even

    handleSaveData(e) {
      if (this.dataSendForm.dayTurns && this.dataSendForm.dayTurns.length > 0) {
        const dayIdCheck = []
        this.listDayCheck.forEach(item => {
          dayIdCheck.push(item.dayId)
        })
        const { dayTurns } = this.dataSendForm
        const arrDayTurn = dayTurns.filter(item => !dayIdCheck.includes(parseInt(item.dayId, 10)))
        this.listDayCheck = [...this.listDayCheck, ...arrDayTurn]
      }
      this.dataSendForm.listDayCheck = this.listDayCheck
      const check = this.checkFormSchedule(this.dataSendForm)
      if (check) {
        this.$emit('saveDataForm', this.dataSendForm)
      }
      e.preventDefault()
    },

    checkFormSchedule(data) {

      if (!this.dataSendForm.hasOwnProperty('creditClassId')) {
        this.showToast('Vui lòng chọn lớp độc lập!', 'XCircleIcon', 'danger')
        return false
      }
      if (!this.dataSendForm.hasOwnProperty('teacherId')) {
        this.showToast('Vui lòng chọn giảng viên!', 'XCircleIcon', 'danger')
        return false
      }
      if (!this.dataSendForm.hasOwnProperty('roomId')) {
        this.showToast('Vui lòng chọn phòng học!', 'XCircleIcon', 'danger')
        return false
      }
      if (!this.dataSendForm.hasOwnProperty('listDayCheck') || this.dataSendForm.listDayCheck.length == 0) {
        this.showToast('Vui lòng chọn nhập thông tin lịch học!', 'XCircleIcon', 'danger')
        return false
      }

      return true
    },

    showToast(title, icon = 'XCircleIcon', variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    async onChangeDepartments(depId) {
      // this.subjects = [];
      const dataObj = {
        currentPage: 1,
        itemsPerPage: 1000,
        organizationId: getUser().orgId,
        departmentId: depId,
      }
      await this.getSubjects(dataObj)
    },

    async onChangeTrainingSystem(SysId) {
      const dataObj = {
        organizationId: getUser().orgId,
        currentPage: 1,
        itemsPerPage: 1000,
        trainingSystemId: SysId,
      }
      await this.getCourses(dataObj)
    },

    async onChangeCourse(courseId) {
      // this.courseSemesters = [];
      const dataObj = {
        organizationId: getUser().orgId,
        courseId,
      }
      await this.getCourseSemesters(dataObj)
    },

    async onChangeCourseSemester(courseSemesterId) {
      const dataObj = {
        organizationId: getUser().orgId,
        courseSemesterId,
        subjectId: this.dataSendForm.subjectId,
        maxStatus: 3,
      }
      await this.getCreditClass(dataObj)
    },

    async onChangeCreditClass(creOId) {
      const beginDate = this.creditClassStartDates.find(e => e.value === creOId).label
      this.dataSendForm.beginDate = beginDate
      const dataObj = {
        organizationId: getUser().orgId,
        creditClassId: creOId,
      }
      await this.getCreditClassRoom(dataObj)
      await this.getTeacherByCreditClass(dataObj)

    },

    async onChangeSubject(subId) {
      const dataObj = {
        organizationId: getUser().orgId,
        subjectId: subId,
        courseSemesterId: this.dataSendForm.courseSemesterId,
        maxStatus: 3,
      }
      await this.getCreditClass(dataObj)
    },

    async getInit() {
      // const dataObj = {
      //   currentPage: 1,
      //   itemsPerPage: 1000,
      // }
      await Promise.all([
        // this.getTeachers(dataObj),
        this.getDepartments({ organizationId: this.user.orgId }),
        this.getTrainingSystems({ organizationId: this.user.orgId }),
        this.getDays(),
        this.getTurns(),
        // this.getRooms({}),
      ])
      this.optDays = this.days.map(item => ({ value: item.code, text: item.name }))
      this.optTurns = this.turns.map(item => ({ value: item.id, text: item.name }))
    },

    checkTurnOfDay(e, dayId) {
      if (this.listDayCheck.length > 0) {
        this.listDayCheck = this.listDayCheck.filter(obj => obj.dayId !== dayId)
      }
      const temp = {
        dayId,
        turnIds: e,
      }
      this.listDayCheck.push(temp)
    },

    onAssignTeacher() {
      if (!this.dataSendForm.creditClassId) return
      this.selectedCreditClass = this.dataSendForm.creditClassId
      this.$refs['modal-assign-teacher'].show()
    },
    async onAssignTeacherOk() {
      const dataObj = {
        creditClassId: this.selectedCreditClass,
      }
      await this.getTeacherByCreditClass(dataObj)
    },
    onAssignRoom() {
      if (!this.dataSendForm.creditClassId) return
      this.selectedCreditClass = this.dataSendForm.creditClassId
      this.$refs['modal-assign-room'].show()
    },
    async onAssignRoomOk() {
      const dataObj = {
        creditClassId: this.selectedCreditClass,
      }
      await this.getCreditClassRoom(dataObj)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
